import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';
import api from '../services/axios';

const statusOptions = [
  { value: 0, label: 'Aguardando Aceite' },
  { value: 1, label: 'Aceito' },
  { value: 2, label: 'Recusado' },
];

const ConviteModal = ({ open, onClose, InvitationId, updateTable }) => {
  const { setLoading } = useLoading();

  const [invitation, setInvitation] = useState({
    email: '',
    user_id: '',
    type: '',
    status: 0, // Inicialmente "Aguardando Aceite" ao criar novo convite
  });
  const [users, setUsers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/users');
        setUsers(data.users);
      } catch (err) {
        console.error(err);
        toast.error('Erro ao carregar usuários.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [setLoading]);

  useEffect(() => {
    const fetchInvitation = async () => {
      if (InvitationId) {
        try {
          setLoading(true);
          const { data } = await api.get(`/invitations/${InvitationId}`);
          setInvitation({
            email: data.invitation.email,
            user_id: data.invitation.user_id,
            type: data.invitation.type,
            status: data.invitation.status,
          });
          setEditing(true); 
        } catch (err) {
          console.error(err);
          toast.error('Erro ao carregar convite.');
        } finally {
          setLoading(false);
        }
      } else {
        setInvitation({
          email: '',
          user_id: '',
          type: '',
          status: 0, // Inicialmente "Aguardando Aceite" ao criar novo convite
        });
        setEditing(false); 
      }
    };

    fetchInvitation();
  }, [InvitationId, setLoading]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let responseData;
      if (InvitationId) {
        const { data } = await api.put(`/invitations/${InvitationId}`, invitation);
        responseData = data.invitation;
        toast.success('Convite atualizado com sucesso.');
      } else {
        const { data } = await api.post('/invitations', invitation);
        responseData = data.invitation;
        toast.success('Convite criado com sucesso.');
      }

      updateTable(responseData);
      onClose();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao salvar convite.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvitation((prevInvitation) => ({
      ...prevInvitation,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{InvitationId ? 'Editar Convite' : 'Criar Novo Convite'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          name="email"
          value={invitation.email}
          onChange={handleChange}
          required
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Usuário</InputLabel>
          <Select
            value={invitation.user_id}
            onChange={handleChange}
            name="user_id"
            required
          >
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!InvitationId && (
          <FormControl fullWidth margin="dense">
            <InputLabel>Tipo</InputLabel>
            <Select
              value={invitation.type}
              onChange={handleChange}
              name="type"
              required
            >
              <MenuItem value="tipo1">Tipo 1</MenuItem>
              <MenuItem value="tipo2">Tipo 2</MenuItem>
            </Select>
          </FormControl>
        )}
        {editing && ( // Mostrar campo de status apenas durante a edição
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={invitation.status}
              onChange={handleChange}
              name="status"
              required
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">
          {InvitationId ? 'Salvar' : 'Criar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConviteModal;
