import React, { useEffect, useState } from "react";
import api from "../services/axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";

const VeiculoModal = ({ open, onClose, vehicleId, updateTable }) => {
  const initialVehicle = {
    user_id: "",
    brand: "",
    model: "",
    plate: "",
    description: "",
    vehicle_type_id: "",
    color_id: "",
  };

  const [vehicle, setVehicle] = useState(initialVehicle);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [colors, setColors] = useState([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (vehicleId) {
      fetchVehicle();
    } else {
      setVehicle(initialVehicle);
    }
  }, [vehicleId]);

  useEffect(() => {
    fetchUsers();
    fetchVehicleTypes();
    fetchColors();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/users");
      setUsers(data.users);
    } catch (err) {
      console.log(err);
      handleError("Erro ao buscar usuários.");
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicleTypes = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/vehicle_types");
      setVehicleTypes(data.vehicle_types);
    } catch (err) {
      console.log(err);
      handleError("Erro ao buscar tipos de veículos.");
    } finally {
      setLoading(false);
    }
  };

  const fetchColors = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/colors");
      setColors(data.colors);
    } catch (err) {
      console.log(err);
      handleError("Erro ao buscar cores.");
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicle = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/vehicles/${vehicleId}`);
      setVehicle(data.vehicle);
    } catch (err) {
      console.error(err);
      setError("Erro ao buscar o veículo.");
      handleError("Erro ao buscar o veículo.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let responseData;
      if (vehicleId) {
        responseData = await api.put(`/vehicles/${vehicleId}`, vehicle);
        toast.success("Veículo atualizado com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        responseData = await api.post("/vehicles", vehicle);
        toast.success("Veículo criado com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      updateTable(responseData.data.vehicle);
      onClose();
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Erro ao salvar o veículo.");
      handleError(err.response?.data?.message || "Erro ao salvar o veículo.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicle({ ...vehicle, [name]: value });
  };

  const handleError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{vehicleId ? "Editar Veículo" : "Criar Novo Veículo"}</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              <FormControl>
                <InputLabel>Usuário</InputLabel>
                <Select
                  label="Usuário"
                  name="user_id"
                  value={vehicle.user_id}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">Nenhum</MenuItem>
                  {users.map((user) => (
                    <MenuItem value={user.user_id} key={user.user_id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Tipo de Veículo</InputLabel>
                <Select
                  label="Tipo de Veículo"
                  name="vehicle_type_id"
                  value={vehicle.vehicle_type_id}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">Nenhum</MenuItem>
                  {vehicleTypes.map((type) => (
                    <MenuItem value={type.vehicle_type_id} key={type.vehicle_type_id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>Cor</InputLabel>
                <Select
                  label="Cor"
                  name="color_id"
                  value={vehicle.color_id}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">Nenhum</MenuItem>
                  {colors.map((color) => (
                    <MenuItem value={color.color_id} key={color.color_id}>
                      {color.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Marca"
                name="brand"
                type="text"
                value={vehicle.brand}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                error={!!error}
                helperText={error}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Modelo"
                name="model"
                type="text"
                value={vehicle.model}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                error={!!error}
                helperText={error}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Placa"
                name="plate"
                type="text"
                value={vehicle.plate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                error={!!error}
                helperText={error}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Descrição"
                name="description"
                type="text"
                value={vehicle.description}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button onClick={onClose} variant="outlined" size="small">
            Cancelar
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained" size="small">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VeiculoModal;
