import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import PerfilModal from 'components/PerfilModal'; // * IMPORTAÇÃO DO MODAL
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';

const Profile = () => {
  const { setLoading } = useLoading();

  // * VARIAVEIS MODAL
  const [modalOpen, setModalOpen] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [rowId, setRowId] = useState(null);

  // * DADOS TABELA
  const [tableData, setTableData] = useState({});
  const [rowCount, setRowCount] = useState(0);

  // * FILTERS
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

  // * RODA QUANDO ABRE A TELA
  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
    
      // * BUSCA TODOS PATROCINIO
      const fetchProfile = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/profiles', { // * LIST
            params: filters
          }); 
          setTableData(data.profiles);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchProfile();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  // * ABRIR E FECHAR MODAL (EDIÇÃO E CRIAÇÃO)
  const handleOpenCreateModal = () => {
    setRowId(null);
    setProfileId(null);
    setModalOpen(true);
  }

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setProfileId(row.original.profile_id);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setRowId(null);
    setProfileId(null);
    setModalOpen(false);
  }

  // * ATUALIZA A TABELA AO CRIAR OU EDITAR ITEM
  const updateTable = (value) => {
    if (rowId) {
      tableData[rowId] = value;
      setTableData([...tableData]);
    } else {
      tableData.push(value);
      setTableData([...tableData]);
    }
  }

  // * FUNÇÃO DE DELETAR
  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma exclusão de ${row.getValue('profile_id')}`)) return;
        
      try {
        setLoading(true);
        await api.delete('/profiles/' + row.getValue('profile_id')); // * DELETE

        tableData.splice(row.index, 1);
        setTableData([...tableData]);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [tableData, setLoading],
  );

  // * COLUNAS DA TABELA
  const columns = useMemo(
    () => [
      {
        accessorKey: 'profile_id', // * NOME DA COLUNA (VEM DA API)
        header: 'ID', // * NOME DA COLUNA (TELA)
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: 'Nome',
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`, 
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          style: {
            width: '100%',
          }
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}

        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}
        columns={columns}
        data={tableData}
        editingMode="modal"

        initialState={{ 
          columnVisibility: { profile_id: false },  
          columnOrder: [
            'profile_id',
            'name',
            'created_at',
            'mrt-row-actions', // * AÇÕES
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => handleOpenEditModal(row)}> {/* FUNÇÃO PARA ABRIR MODAL DE EDIÇÃO */}
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="primary"
            onClick={handleOpenCreateModal} // * FUNÇÃO PARA ABRIR MODAL DE CRIAÇÃO
            variant="contained"
          >
            Criar Novo
          </Button>
        )}
      />
      <PerfilModal // * MODAL DE CRIAÇÃO E EDIÇÃO
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        profileId={profileId}
      />
    </>
  );
};

export default Profile;
