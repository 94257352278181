import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  MenuItem,
} from '@mui/material';
import api from '../services/axios';
import { toast } from 'react-toastify';

const PlanoModal = ({ open, onClose, planId, updateTable }) => {
  const initialPlan = {
    name: '',
    value: '',
    description: '',
    active: true,
  };
  const [plan, setPlan] = useState(initialPlan);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (planId) {
      fetchPlan();
    } else {
      setPlan(initialPlan);
    }
  }, [planId, open]);

  const fetchPlan = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/plans/${planId}`);
      setPlan(response.data.plan);
      setLoading(false);
    } catch (err) {
      console.error('Erro ao buscar o plano:', err);
      setError('Erro ao buscar o plano.');
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlan((prevPlan) => ({
      ...prevPlan,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let response;
      if (planId) {
        response = await api.put(`/plans/${planId}`, plan);
        toast.success('Plano Atualizado Com Sucesso!');
      } else {
        response = await api.post('/plans', plan);
        toast.success('Plano Criado Com Sucesso!');
      }
      updateTable(response.data.plan);
      onClose();
    } catch (err) {
      console.error('Erro ao salvar o plano:', err);
      setError(err.response?.data?.message || 'Erro ao salvar o plano.');
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await api.delete(`/plans/${planId}`);
      toast.success('Plano Excluído Com Sucesso!');
      updateTable(planId, 'delete');
      onClose();
    } catch (err) {
      console.error('Erro ao excluir o plano:', err);
      setError(err.response?.data?.message || 'Erro ao excluir o plano.');
    }
    setLoading(false);
  };

  const handleClose = () => {
    setError('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{planId ? `Editar Plano ${plan.name}` : 'Criar Novo Plano'}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="Nome"
            name="name"
            value={plan.name}
            onChange={handleChange}
            fullWidth
            required
            error={!!error}
            helperText={error}
          />
          <TextField
            label="Valor"
            name="value"
            value={plan.value}
            onChange={handleChange}
            fullWidth
            required
            type="number"
            InputProps={{
              inputProps: {
                step: '0.01',
                min: '0',
              },
            }}
          />
          <TextField
            label="Descrição"
            name="description"
            value={plan.description || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            select
            label="Ativo"
            name="active"
            value={plan.active}
            onChange={handleChange}
            fullWidth
            required
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        {planId && (
          <Button onClick={handleDelete} color="error" disabled={loading}>
            Excluir
          </Button>
        )}
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {loading ? 'Aguarde...' : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlanoModal;
