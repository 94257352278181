import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, Select, Stack, TextField, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import api from "services/axios";

// * NOME DA FUNÇÃO = NOME DO ARQUIVO
export const PatrocinioModal = ({ open, onClose, sponsorshipId, updateTable }) => {
    const initialSponsorship = { // * CAMPOS INICIAIS + VALORES INICIAIS
      sponsor_id: '',
      file: '',
      link: '',
      description: '',
      initial_date: '',
      final_date: '',
      enabled: true,
    };
    const [sponsorship, setSponsorship] = useState(initialSponsorship); // * VARIAVEL DO ITEM

    const [sponsors, setSponsors] = useState([]); // * LISTA DE PATROCINADORES
  
    useEffect(() => {
      const fetchSponsors = async () => { // * BUSCAR TODOS OS PATROCINADORES
        try {
          const { data } = await api.get('/sponsors');

          setSponsors(data.sponsors);
        } catch (err) {
          console.log(err);
        }
      }

      const fetchSponsorship = async () => { // * BUSCA A INFORMAÇÃO QUANDO FOR EDITAR
        try {
          if (!sponsorshipId) return; // * VERIFICA SE É EDIÇÃO OU CRIAÇÃO

          const { data } = await api.get(`/sponsorships/${sponsorshipId}`); // * GET 

          setSponsorship((prev) => ({
            ...prev,
            ...data.sponsorship,
            initial_date: new Date(new Date(data.sponsorship.initial_date).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
            final_date: new Date(new Date(data.sponsorship.final_date).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0]
          }));
        } catch (err) {
          console.log(err);
        }
      }

      fetchSponsors();
      fetchSponsorship();
    }, [sponsorshipId]);
  
    const handleSubmit = async () => { // * CRIAR / EDITAR
      console.log(sponsorship);

      try {
        if (!sponsorship.file) {
          alert('Arquivo é obrigatório!!!')
          return;
        }
  
        if (!sponsorship.sponsor_id) {
          alert('Patrocinador é obrigatório!!!')
          return;
        }
  
        if (!sponsorship.description) {
          alert('Descrição é obrigatório!!!')
          return;
        }
  
        if (!sponsorship.link) {
          alert('Link é obrigatório!!!')
          return;
        }
  
        if (sponsorshipId) {
          const { data } = await api.put(`/sponsorships/${sponsorshipId}`, sponsorship); // * UPDATE
          updateTable(data.sponsorship);
        } else {
          const { data } = await api.post('/sponsorships/', sponsorship) // * CRIAÇÃO
          updateTable(data.sponsorship);
        }

        onClose();
        setSponsorship(initialSponsorship);
      } catch (err) {
        console.log(err);
      }
    };
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">{ sponsorshipId ? 'Editar' : 'Criar Novo' } Patrocínio { sponsorshipId ? `#${sponsorshipId}` : '' }</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: '16px' }}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {/* COMBO BOX / SELECT / LISTA - VÁRIAS OPÇÕES */}
              <FormControl> 
                <InputLabel>{"Patrocinador"}</InputLabel>
                <Select
                  label={'Patrocinador'}
                  name={'sponsor_id'}
                  type={'text'}
                  value={sponsorship['sponsor_id']}
                  onChange={(e) =>
                    setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                  }
                >
                  <MenuItem value={''}>{"Nenhum"}</MenuItem>
                  { sponsors.map(sponsor => (
                    <MenuItem value={sponsor.sponsor_id} key={sponsor.sponsor_id}>{sponsor.nickname}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* TEXTO */}
              <TextField 
                label={'Arquivo'}
                name={'file'}
                type={'text'}
                value={sponsorship['file']}
                onChange={(e) =>
                  setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                }
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={'Descrição'}
                name={'description'}
                type={'text'}
                value={sponsorship['description']}
                onChange={(e) =>
                  setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                }
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={'Link'}
                name={'link'}
                type={'text'}
                value={sponsorship['link']}
                onChange={(e) =>
                  setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                }
                InputLabelProps={{ shrink: true }}
              />
               {/* DATAS */}
              <TextField
                label={'Data Inicial'}
                name={'initial_date'}
                type={'datetime-local'}
                value={sponsorship['initial_date']}
                onChange={(e) =>
                  setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                }
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={'Data Final'}
                name={'final_date'}
                type={'datetime-local'}
                value={sponsorship['final_date']}
                onChange={(e) =>
                  setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                }
                InputLabelProps={{ shrink: true }}
              />
              {/* BOOLEAN (TRUE / FALSE) */}
              <FormControl>
                <FormControlLabel
                  label={'Habilitado'}
                  control={
                    <Checkbox
                      color="primary" 
                      name={'enabled'}
                      checked={sponsorship['enabled']}
                      onChange={(e) =>
                        setSponsorship((prev) => ({ ...prev, [e.target.name]: e.target.checked }))
                      }
                    />
                  }
                />
              </FormControl>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default PatrocinioModal;
