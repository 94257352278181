import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../services/axios";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";

export const PerfilModal = ({ open, onClose, profileId, updateTable }) => {
  const { setLoading } = useLoading();
  const initialProfile = {
    name: "",
  };
  const [profile, setProfile] = useState(initialProfile);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      if (profileId) {
        try {
          const { data } = await api.get(`/profiles/${profileId}`);
          setProfile(data.profile); 
        } catch (err) {
          console.log(err);
          setError("Erro ao buscar o Perfil.");
        }
      } else {
        setProfile(initialProfile);
      }
    };

    fetchProfile();
  }, [profileId]);

  const handleSubmit = async () => { // * CRIAR / EDITAR
    console.log(profile);

    try {
      if (!profile.name) {
        toast.error('nome é obrigatório!!!')
        return;
      }

      setLoading(true);

      if (profileId) {
        const { data } = await api.put(`/profiles/${profileId}`, profile); // * UPDATE
        updateTable(data.profile);
        toast.success("Perfil editado com sucesso.");
      } else {
        const { data } = await api.post('/profiles/', profile) // * CRIAÇÃO
        updateTable(data.profile);
        toast.success("Perfil criado com sucesso.");
      }


      onClose();
      setProfile(initialProfile);
    } catch (err) {
      console.log(err);
      toast.error("Erro:", err.message);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {profileId ? `Editar Perfil: ${profile.name}` : "Criar Perfil"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Nome"
              name="name"
              type="text"
              value={profile.name}
              onChange={(e) => setProfile({ ...profile, [e.target.name]: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose} variant="outlined" size="small">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit} variant="contained" size="small">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerfilModal;
