import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import api from "../services/axios";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";

export const UserChannelModal = ({ open, onClose, channelId, userId, updateTable }) => {
  const { setLoading } = useLoading();
  const initialChanneluser = {
    user_id: null,
    channel_id: null,
    is_admin: false,
  };
  const [channeluser, setChannelUser] = useState(initialChanneluser);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const fetchChannelUser = async () => {
      if (channelId && userId) {
        try {
          const { data } = await api.get(`/channel_users/${channelId}/${userId}`);
          setChannelUser(data.channel_user);
        } catch (err) {
          console.log(err);
          setError("Erro ao buscar o usuário.");
        }
      }
    };

    fetchChannelUser();
  }, [channelId, userId]);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const { data } = await api.get("/channels");
        setChannels(data.channels);
      } catch (err) {
        console.log(err);
      }
    };

    fetchChannels();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await api.get("/users");
        setUsers(data.users);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async () => {
    try {

      setLoading(true);

      const channelUserData = { ...channeluser, channelId, userId };

      if (channelId && userId) {
        const { data } = await api.put('/channel_users/', channelUserData);
        updateTable(data.channel_user);
        toast.success("Usuário atualizado com sucesso.");
      } else {
        const { data } = await api.post('/channel_users/', channelUserData);
        updateTable(data.channel_user);
        toast.success("Usuário adicionado com sucesso.");
      }

      onClose();
      setChannelUser(initialChanneluser);
    } catch (err) {
      console.log(err);
      toast.error("Erro:", err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {(channelId && userId) ? `Editar Canal Usuário` : "Criar Usuário Canal"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <FormControl>
              <InputLabel>{"Usuário"}</InputLabel>
              <Select
                label={"Usuário"}
                name={"user_id"}
                value={channeluser.user_id}
                onChange={(e) =>
                  setChannelUser((prev) => ({ ...prev, user_id: e.target.value }))
                }
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                error={!!error}
                disabled={(channelId && userId)}
              >
                <MenuItem value={""}>{"Nenhum"}</MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.user_id} value={user.user_id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>{"Canal"}</InputLabel>
              <Select
                label={"Canal"}
                name={"channel_id"}
                value={channeluser.channel_id}
                onChange={(e) =>
                  setChannelUser((prev) => ({ ...prev, channel_id: e.target.value }))
                }
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                error={!!error}
                disabled={(channelId && userId)}
              >
                <MenuItem value={""}>{"Nenhum"}</MenuItem>
                {channels.map((channel) => (
                  <MenuItem key={channel.channel_id} value={channel.channel_id}>
                    {channel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={channeluser.is_admin}
                  onChange={(e) => setChannelUser({ ...channeluser, is_admin: e.target.checked })}
                />
              }
              label="Administrador"
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose} variant="outlined" size="small">
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          size="small"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserChannelModal;
