import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";
import api from "services/axios";

export const CanalModal = ({ open, onClose, channelId, updateTable }) => {
  const { setLoading } = useLoading();
  const initialChannel = {
    name: '',
    description: '',
    image: null,
  };
  const [channel, setChannel] = useState(initialChannel);

  useEffect(() => {
    const fetchChannel = async () => {
      try {
        if (!channelId) return;

        const { data } = await api.get(`/channels/${channelId}`);
        console.log('Fetched channel data:', data);

        if (data && data.channel) {
          setChannel({
            name: data.channel.name || '',
            description: data.channel.description || '',
            image: null,
          });
        } else {
          console.error('No channel data found.');
          toast.error("Erro ao buscar dados do canal.");
        }
      } catch (err) {
        console.error("Error fetching channel:", err);
        toast.error("Erro ao buscar dados do canal.");
      }
    };

    fetchChannel();
  }, [channelId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setChannel((prev) => ({ ...prev, image: files[0] }));
    } else {
      setChannel((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (!channel.name) {
        toast.error('Nome é obrigatório!');
        return;
      }
      setLoading(true);

      if (!channel.description) {
        toast.error('Descrição é obrigatória!');
        return;
      }

      if (!channel.image && !channelId) {
        toast.error('Imagem é obrigatória!');
        return;
      }

      const formData = new FormData();
      formData.append("name", channel.name);
      formData.append("description", channel.description);
      if (channel.image) formData.append("file", channel.image);

      if (channelId) {
        const { data } = await api.put(`/channels/${channelId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        updateTable(data.channel);
        toast.success("Canal editado com sucesso.");
      } else {
        const { data } = await api.post('/channels/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        updateTable(data.channel);
        toast.success("Canal criado com sucesso.");
      }

      onClose();
      setChannel(initialChannel);
    } catch (err) {
      console.error("Error submitting channel:", err);
      toast.error("Erro ao salvar o canal.");
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">{ channelId ? `Editar Canal #${channel.name}` : 'Criar Novo Canal' }</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: '16px' }}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            <TextField
              label={'Nome'}
              name={'name'}
              type={'text'}
              value={channel.name || ''}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label={'Descrição'}
              name={'description'}
              type={'text'}
              value={channel.description || ''}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <input
              accept="image/*"
              id="channel-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={handleChange}
              name="image"
            />
            <label htmlFor="channel-image-upload">
              <Button variant="outlined" component="span">
                Upload Imagem
              </Button>
            </label>
            {channel.image && <span>{channel.image.name}</span>}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CanalModal;
