import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';


const statusOptions = [
  { value: 0, label: 'Aguardando Aceite' },
  { value: 1, label: 'Aceito' },
  { value: 2, label: 'Recusado' },
];

const Denuncia = () => {
  const { setLoading } = useLoading();

  // * VARIAVEIS MODAL
  const [modalOpen, setModalOpen] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [tableData, setTableData] = useState({});
  const [rowCount, setRowCount] = useState(0);


  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

    useEffect(() => {
      setLoading(true);

      const delay = setTimeout(() => {
   
      // * BUSCA TODOS PATROCINIO
      const fetchReport = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/reports', { // * LIST
            params: filters
          }); 
          setTableData(data.reports);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchReport();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);


  const handleOpenCreateModal = () => {
    setRowId(null);
    setReportId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setReportId(row.original.report_id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setRowId(null);
    setReportId(null);
    setModalOpen(false);
  };

  const updateTable = (value) => {
    if (rowId) {
      tableData[rowId] = value;
      setTableData([...tableData]);
    } else {
      tableData.push(value);
      setTableData([...tableData]);
    }
  }

  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma exclusão de ${row.getValue('report_id')}`)) return;
        
      try {
        setLoading(true);
        await api.delete('/reports/' + row.getValue('report_id')); // * DELETE

        tableData.splice(row.index, 1);
        setTableData([...tableData]);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [tableData, setLoading],
  );
  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'report_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'user.name',
        header: 'Nome do usuário',
        Cell: ({ row }) => row.original.user?.name || 'Usuário Desconhecido',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => {
          const statusValue = row.original.status;
          const statusOption = statusOptions.find(option => option.value == statusValue);
          return statusOption ? statusOption.label : 'Desconhecido';
        },
      },     
      {
        accessorKey: 'description',
        header: 'Descrição',
      },
      {
        accessorKey: 'result', 
        header: 'Resultado',
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`, 
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          style: {
            width: '100%',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}
        initialState={{
          columnVisibility: { report_id: false },
          columnOrder: [
            'report_id',
            'user.name',
            'user_id',
            'status',
            'description',
            'result', 
            'created_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Editar">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Excluir">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default Denuncia;
