import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../services/axios";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";

export const CategoriaModal = ({ open, onClose, categoryId, updateTable }) => {
  const { setLoading } = useLoading();
  const initialCategory = {
    name: "",
  };
  const [category, setCategory] = useState(initialCategory);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCategory = async () => {
      if (categoryId) {
        try {
          const { data } = await api.get(`/categories/${categoryId}`);
          setCategory(data.category); 
        } catch (err) {
          console.log(err);
          setError("Erro ao buscar a Categoria.");
        }
      } else {
        setCategory(initialCategory);
      }
    };

    fetchCategory();
  }, [categoryId]);

  const handleSubmit = async () => { // * CRIAR / EDITAR
    console.log(category);

    try {
      if (!category.name) {
        toast.error('nome é obrigatório!!!')
        return;
      }

      setLoading(true);

      if (categoryId) {
        const { data } = await api.put(`/categories/${categoryId}`, category);
        console.log(data) // * UPDATE
        updateTable(data.category);
        toast.success("Categoria editada com sucesso.");
      } else {
        const { data } = await api.post('/categories/', category) // * CRIAÇÃO
        updateTable(data.category);
        toast.success("Categoria criada com sucesso.");
      }

      onClose();
      setCategory(initialCategory);
    } catch (err) {
      console.log(err);
      toast.error("Erro:", err.message);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {categoryId ? `Editar categoria ${categoryId}` : "Criar categoria"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Nome"
              name="name"
              type="text"
              value={category.name}
              onChange={(e) => setCategory({ ...category, [e.target.name]: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose} variant="outlined" size="small">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit} variant="contained" size="small">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoriaModal;
