import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import TipoVeiculoModal from 'components/TipoVeiculoModal';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';

const TipoVeiculo = () => {
  const { setLoading } = useLoading();

  const [modalOpen, setModalOpen] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  // * FILTERS
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const fetchVehicleTypes = async () => {
    setLoading(true);
    try {
      const filters = {
        search: globalFilter,
        page_number: pagination.pageIndex,
        limit: pagination.pageSize,
        sort_by: sorting.length ? sorting[0].id : 'vehicle_type_id',
        sort_type: sorting.length ? sorting[0].desc ? 'DESC' : 'ASC' : 'ASC',
      };
      columnFilters.forEach(filter => {
        filters[filter.id] = filter.value;
      });

      const { data } = await api.get('/vehicle_types', { params: filters });
      setTableData(data.vehicle_types);
      setRowCount(data.count);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
    
      // * BUSCA TODOS PATROCINIO
      const fetchVehicleTypes = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/vehicle_types', { // * LIST
            params: filters
          }); 
          setTableData(data.vehicle_types);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchVehicleTypes();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);


  const handleOpenCreateModal = () => {
    setRowId(null);
    setVehicleId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setVehicleId(row.original.vehicle_type_id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setRowId(null);
    setVehicleId(null);
    setModalOpen(false);
  };

  const updateTable = useCallback((updatedVehicle) => {
    if (rowId) {
      const updatedData = tableData.map((vehicle) =>
        vehicle.vehicle_type_id === updatedVehicle.vehicle_type_id ? updatedVehicle : vehicle
      );
      setTableData(updatedData);
    } else {
      setTableData([...tableData, updatedVehicle]);
    }
  }, [tableData, rowId]);

  const handleDeleteRow = useCallback(async (row) => {
    if (!window.confirm(`Confirma exclusão de ${row.original.name}`)) return;

    try {
      setLoading(true);
      await api.delete(`/vehicle_types/${row.original.vehicle_type_id}`);
      const updatedData = tableData.filter((item) => item.vehicle_type_id !== row.original.vehicle_type_id);
      setTableData(updatedData);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [tableData, setLoading]);

  const columns = useMemo(() => [
    {
      accessorKey: 'vehicle_type_id',
      header: 'ID',
      enableColumnOrdering: false,
      enableSorting: false,
    },
    {
      accessorKey: 'name',
      header: 'Veículo',
    },{
     accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`, 
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );


  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          style: {
            width: '100%',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"

        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}

        initialState={{
          columnVisibility: { vehicle_type_id: false },
          columnOrder: [
            'vehicle_type_id',
            'name',
            'created_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" onClick={handleOpenCreateModal} variant="contained">
            Criar Novo
          </Button>
        )}
      />
      <TipoVeiculoModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        vehicleId={vehicleId}
      />
    </>
  );
};

export default TipoVeiculo;
