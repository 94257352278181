import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  FormControl
} from "@mui/material";
import api from "../services/axios";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";
import LocationAutoComplete from "./LocationAutoComplete";


export const UserModal = ({ open, onClose, userId, updateTable }) => {
  const { setLoading } = useLoading();
  const initialUser = {
    name: "",
    user_id: "",
    image: null,
    cell_phone: "",
    email: "",
    city: "",
    address: "",
    enabled: false,
    profile_id: "",
    plan_id: "", 
    password: "", 
    active: false, 
    verified: false, 
    number: "",
    emergency_contact: "" 
  };
  const [user, setUser] = useState(initialUser);
  const [error] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [plans, setPlans] = useState([]);


  const [initialLocation, setInitialLocation] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    console.log(location);
  }, [location]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!userId) return;

        const { data } = await api.get(`/users/${userId}`);
        console.log('Fetched user data:', data);

           setInitialLocation({
            lat: data.user.latitude,
            lng: data.user.longitude,
            city: data.user.city,
            address: data.user.address,
            state: data.user.state
          });

        if (data && data.user) {
          setUser(data.user);
        } else {
          console.error('No user data found.');
          toast.error("Erro ao buscar dados do canal.");
        }
      } catch (err) {
        console.error("Error fetching user:", err);
        toast.error("Erro ao buscar dados do canal.");
      }
    };

    fetchUser();
  }, [userId]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const { data } = await api.get("/profiles");
        setProfiles(data.profiles);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    const fetchPlans = async () => {
      try{
        const { data } = await api.get("/plans");
        setPlans(data.plans);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPlans();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setUser((prev) => ({ ...prev, image: files[0] }));
    } else {
      setUser((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    console.log(user);

    try {
      if (!user.name) {
        toast.error('Nome é obrigatório!!!')
        return;
      }
      setLoading(true);

      if (!user.cell_phone) {
        toast.error('Telefone é obrigatório!!!')
        return;
      }

      if (!user.email) {
        toast.error('Email é obrigatório!!!')
        return;
      }

      if (!user.password) {
        toast.error('Senha é obrigatório!!!')
        return;
      }
      if (!user.profile_id) {
        toast.error('Perfil é obrigatório!!!')
        return;
      }
      if (!user.plan_id) {
        toast.error('Plano é obrigatório!!!')
        return;
      }
      if(!user.number){
        toast.error('Número obrigatório!!')
        return;
      }
      if(!user.emergency_contact){
        toast.error('Número de emergência obrigatório')
      }
      const formData = new FormData();
      formData.append("name", user.name);
      formData.append("password", user.password);
      formData.append("email", user.email);
      formData.append("cell_phone", user.cell_phone);
      formData.append("number", user.number);
      formData.append("plan_id", user.plan_id);
      formData.append("user_id", user.user_id);
      formData.append("profile_id", user.profile_id);
      formData.append("latitude", user.latitude);
      formData.append("longitude", user.longitude);
      formData.append("state", user.state);
      formData.append("city", user.city);
      formData.append("emergency_contact", user.emergency_contact);





      if (user.image) formData.append("file", user.image);
      

      user.state = location.state;
      user.latitude = location.lat;
      user.longitude = location.lng;
      user.city = location.city; 

      if (userId) {
        const { data } = await api.put(`/users/${userId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }})
        updateTable(data.user);
        toast.success("Usuário editado com sucesso.");
      } else {
        const { data } = await api.post('/users/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }})
          updateTable(data.user)
        toast.success("Usuário criado com sucesso.");
      }

      onClose();
      setUser(initialUser);
    } catch (err) {
      console.log(err);
      toast.error("Erro:", err.message);
    }
    setLoading(false);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {userId ? `Editar Usuário ${user.name}` : "Criar Usuário"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <FormControl>
            <InputLabel>{"Perfil"}</InputLabel>
            <Select
              label={"Perfil"}
              name={"profile_id"}
              value={user.profile_id}
              onChange={(e) =>
                setUser((prev) => ({ ...prev, profile_id: e.target.value }))
              }
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            >
              <MenuItem value={""}>{"Nenhum"}</MenuItem>
              {profiles.map((profile) => (
                <MenuItem key={profile.profile_id} value={profile.profile_id}>
                  {profile.name}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <FormControl>

            <InputLabel>{"Planos"}</InputLabel>
            <Select
            label={"Planos"}
            name={"plan_id"}
            value={user.plan_id}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, plan_id: e.target.value }))
            }
            fullWidth
            required
            error={!!error}
            helperText={error}
            variant="outlined"
            size="small"
          >
            <MenuItem value={""}>{"Nenhum"}</MenuItem>
            {plans.map((plan) => ( 
              <MenuItem key={plan.plan_id} value={plan.plan_id}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>

            </FormControl>

            <TextField
              label="Nome"
              name="name"
              type="text"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
            
           
            <TextField
              label="Celular"
              name="cell_phone"
              type="text"
              value={user.cell_phone}
              onChange={(e) => setUser({ ...user, cell_phone: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
             <TextField
              label="Contato de emergência"
              name="emergency_contact"
              type="text"
              value={user.emergency_contact}
              onChange={(e) => setUser({ ...user, emergency_contact: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
            <TextField
              label="Senha"
              name="password"
              type="password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />

            <LocationAutoComplete setLocation={setLocation} initialLocation={initialLocation} />

            <TextField
              label="Complemento (Endereço)"
              name="address"
              type="text"
              value={user.address}
              onChange={(e) => setUser({ ...user, address: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />

            <TextField
              label="Número (Endereço)"
              name="number"
              type="text"
              value={user.number}
              onChange={(e) => setUser({ ...user, number: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
            
            
            <FormControlLabel
              label="Ativo"
              control={
                <Checkbox
                  color="primary"
                  name="active"
                  checked={user.active}
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.checked,
                    }))
                  }
                />
              }
            />
            <FormControlLabel
              label="Verificado"
              control={
                <Checkbox
                  color="primary"
                  name="verified"
                  checked={user.verified}
                  onChange={(e) =>
                    setUser((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.checked,
                    }))
                  }
                />
              }
            />
             <input
              accept="image/*"
              id="user-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={handleChange}
              name="image"
            />
            <label htmlFor="user-image-upload">
              <Button variant="outlined" component="span">
                Upload Imagem
              </Button>
            </label>
            {user.image && <span>{user.image.name}</span>}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose} variant="outlined" size="small">
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          size="small"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
