import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { toast } from 'react-toastify';
import api from '../services/axios';
import PlanoModal from 'components/PlanosModal';

// Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';

const Planos = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  // * FILTERS
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

  useEffect(() => {
    const delay = setTimeout(() => {
      const fetchPlans = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });
    
          const { data } = await api.get('/plans', {
            params: filters
          });
          setTableData(data.plans);
          setRowCount(data.count);
        } catch (err) {
          console.error('Erro ao buscar planos:', err);
          toast.error(err.message);
        }
      };
      
      fetchPlans();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setPlanId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setPlanId(row.getValue('plan_id'));
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setPlanId(null);
    setModalOpen(false);
  };

  const updateTable = useCallback((updatedPlan, action) => {
    if (action === 'delete') {
      const updatedData = tableData.filter((plan) => plan.plan_id !== updatedPlan);
      setTableData(updatedData);
    } else if (planId) {
      const updatedData = tableData.map((plan) =>
        plan.plan_id === updatedPlan.plan_id ? updatedPlan : plan
      );
      setTableData(updatedData);
    } else {
      setTableData([...tableData, updatedPlan]);
    }
  }, [tableData, planId]);

  const handleDeleteRow = useCallback(async (row) => {
    if (!window.confirm(`Confirma exclusão de ${row.getValue('name')}?`)) return;

    try {
      await api.delete(`/plans/${row.getValue('plan_id')}`);
      updateTable(row.getValue('plan_id'), 'delete');
      toast.success('Plano excluído com sucesso!');
    } catch (err) {
      console.error('Erro ao excluir o plano:', err);
      toast.error(err.response?.data?.message || 'Erro ao excluir o plano.');
    }
  }, [updateTable]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'plan_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: 'Nome',
      },
      {
        accessorKey: 'value',
        header: 'Valor',
      },
      {
        accessorKey: 'description',
        header: 'Descrição',
        Cell: ({ cell }) => cell.getValue() || 'Sem descrição',
      },
      {
        accessorFn: (row) => row.active ? 'Sim' : 'Não', // * PARA TELA - COLUNA DE BOOLEAN (TRUE / FALSE)
        accessorKey: 'active',
        header: 'Ativo',
        Cell: ({ cell }) => (cell.getValue() ? 'Sim' : 'Não'),
        filterVariant: 'checkbox'
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          style: {
            width: '100%',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"

        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}

        initialState={{
          columnVisibility: { plan_id: false },
          columnOrder: ['plan_id', 'name', 'description', 'value', 'active', 'created_at', 'mrt-row-actions'],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Editar">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Excluir">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" onClick={handleOpenCreateModal} variant="contained">
            Criar Novo
          </Button>
        )}
      />
      <PlanoModal
        open={modalOpen}
        onClose={handleCloseModal}
        planId={planId}
        updateTable={updateTable}
      />
    </>
  );
};

export default Planos;
