import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, Select, Stack, TextField, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import api from "services/axios";

export const PatrocinadorModal = ({open, onClose, updateTable, sponsorId}) => {
const initialSponsor = {
  cnpj: '',
  nickname: '',
  name: '',
  created_at:'',
  enabled: true,
};

  const [sponsor, setSponsor] = useState(initialSponsor);

  useEffect(() => {
    const fetchSponsor = async () => {
      if (!sponsorId) return;

      try {
        const { data } = await api.get(`/sponsors/${sponsorId}`);
        setSponsor(data.sponsor); // Define o patrocinador para edição se sponsorId for fornecido
      } catch (err) {
        console.error(err);
      }
    };

    fetchSponsor();
  }, [sponsorId]);

  const handleSubmit = async () => {
    try {
      if (!sponsor.cnpj || !sponsor.nickname || !sponsor.name) {
        alert('Todos os campos são obrigatórios!!!');
        return;
      }

      if (sponsorId) {
        const { data } = await api.put(`/sponsors/${sponsorId}`, sponsor); // * UPDATE
        updateTable(data.sponsor);
      } else {
        const { data } = await api.post('/sponsors/', sponsor) // * CRIAÇÃO
        updateTable(data.sponsor);
      }


      onClose();
      setSponsor(initialSponsor);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center">
        {sponsorId ? 'Editar' : 'Criar Novo'} Patrocinador {sponsorId ? `#${sponsorId}` : ''}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: '16px' }}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            <TextField
              label="CNPJ"
              name="cnpj"
              type="text"
              value={sponsor.cnpj}
              onChange={(e) => setSponsor({ ...sponsor, cnpj: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Apelido"
              name="nickname"
              type="text"
              value={sponsor.nickname}
              onChange={(e) => setSponsor({ ...sponsor, nickname: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Nome"
              name="name"
              type="text"
              value={sponsor.name}
              onChange={(e) => setSponsor({ ...sponsor, name: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PatrocinadorModal;
