import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

api.interceptors.request.use(
    config => {
        const token = cookies.get("TOKEN");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => { return response },
    error => {
        if (error?.response?.status === 403) {
            cookies.remove("TOKEN", { path: "/" });

            window.location.href = "/";
        }
        return Promise.reject(error);
    }
)

export default api;
