import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../services/axios";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";

export const CorModal = ({ open, onClose, colorId, updateTable }) => {
  const { setLoading } = useLoading();
  const initialColor = {
    name: "",
  };
  const [color, setColor] = useState(initialColor);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchColor = async () => {
      if (colorId) {
        try {
          setLoading(true);
          const { data } = await api.get(`/colors/${colorId}`);
          setColor(data.color); 
        } catch (err) {
          console.log(err);
          setError("Erro ao buscar a Cor.");
        }
        setLoading(false);
      } else {
        setColor(initialColor);
      }
    };

    fetchColor();
  }, [colorId, setLoading]);

  const handleSubmit = async () => {
    if (!color.name.trim()) {
      setError("Nome da cor é obrigatório.");
      return;
    }

    try {
      setLoading(true);
      let responseData;
      if (colorId) {
        responseData = await api.put(`/colors/${colorId}`, color);
        toast.success("Cor Atualizado Com Sucesso!");
      } else {
        responseData = await api.post('/colors', color);
        toast.success("Cor Criado Com Sucesso!");
      }
      updateTable(responseData.data.color);
      onClose();
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Erro ao salvar a cor.");
    } 
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {colorId ? `Editar cor ${colorId}` : "Criar cor"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Nome"
              name="name"
              type="text"
              value={color.name}
              onChange={(e) => setColor({ ...color, [e.target.name]: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose} variant="outlined" size="small">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit} variant="contained" size="small">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CorModal;
