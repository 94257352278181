import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function ProtectedRoutes({ isAuth, component: Component, ...rest }) {
    const token = cookies.get("TOKEN");

    if (isAuth) {
        return token ? <Outlet /> : <Navigate to="/" />
    }

    return token ? <></> : <Outlet />;
}
