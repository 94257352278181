import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../services/axios";
import { toast } from "react-toastify";
import { useLoading } from "context/LoadingContext";

const TipoVeiculoModal = ({ open, onClose, vehicleId, updateTable }) => {
  const { setLoading } = useLoading();

  const initialVehicle = {
    name: "",
  };
  const [vehicle, setVehicle] = useState(initialVehicle);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchVehicle = async () => {
      if (vehicleId) {
        try {
          setLoading(true);
          const { data } = await api.get(`/vehicle_types/${vehicleId}`);
          setVehicle(data.vehicle_type); // Acessar 'vehicle_type' diretamente
        } catch (err) {
          console.log(err);
          setError("Erro ao buscar o tipo de veículo.");
        }
        setLoading(false);
      } else {
        setVehicle(initialVehicle);
      }
    };

    fetchVehicle();
  }, [vehicleId, setLoading]);

  const handleSubmit = async () => {
    if (!vehicle.name.trim()) {
      setError("Nome do veículo é obrigatório.");
      return;
    }

    try {
      setLoading(true);
      let responseData;
      if (vehicleId) {
        responseData = await api.put(`/vehicle_types/${vehicleId}`, vehicle);
        toast.success("Tipo de Veículo Atualizado Com Sucesso!");
      } else {
        responseData = await api.post('/vehicle_types', vehicle);
        toast.success("Tipo de Veículo Criado Com Sucesso!");
      }
      updateTable(responseData.data.vehicle_type); // Acessar 'data' diretamente no retorno da API
      onClose();
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Erro ao salvar o tipo de veículo.");
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {vehicleId ? "Editar" : "Tipo Veículo "}  {vehicleId ? `#${vehicleId}` : ""}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()} style={{ marginTop: "16px" }}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <TextField
              label="Nome do Veículo"
              name="name"
              type="text"
              value={vehicle.name}
              onChange={(e) => {
                setVehicle({ ...vehicle, [e.target.name]: e.target.value });
                if (error) setError("");
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              error={!!error}
              helperText={error}
              variant="outlined"
              size="small"
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose} variant="outlined" size="small">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit} variant="contained" size="small">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TipoVeiculoModal;
