const formatFilter = ({ columnFilters, globalFilter, pagination, sorting }) => {
    const filters = {};
    const dateFilters = ['created_at', 'initial_date', 'final_date'];

    for (const columnFilter of columnFilters) {
      filters[columnFilter.id] = columnFilter.value;

      if (dateFilters.includes(columnFilter.id)) {
        filters[columnFilter.id] = columnFilter.value.format('YYYY-MM-DD');
      }
    }

    if (globalFilter) {
      filters['search'] = globalFilter;
    }

    filters['page_number'] = pagination.pageIndex; 
    filters['limit'] = pagination.pageSize;

    if (sorting.length > 0) {
      filters['sort_by'] = sorting[0].id;
      filters['sort_type'] = sorting[0].desc ? 'DESC' : 'ASC';
    }

    return filters;
}

export { formatFilter };
