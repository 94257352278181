import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar, Button, Container, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "services/axios";
import { toast } from 'react-toastify';


import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    setLoading(true);

    e.preventDefault();
    
    try {
      const userData = {
        email,
        password,
      };

      const result = await api.post('/login', userData);

      cookies.set("TOKEN", result.data.accessToken, {
        path: "/",
      });

      window.location.href = "/Usuario";
    } catch (err) {
        toast.error("E-mail ou senha inválidos!");
    }

    setLoading(false);
  }

  return (
    <div
        style={{
            paddingTop: "200px",
            width: "100%",
            height: "100%",
        }}
    >  	
        <Container component="main" maxWidth="xs">
            <div>
                <Paper elevation={5} 
                    style={{
                        padding: 15,
                        borderRadius: 10,
                        display: "flex",
                    }}
                >
                    <div 
                        style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <form 
                            noValidate 
                            onSubmit={handleLogin}
                            style={{
                                width: "100%",
                                marginTop: "8px",
                            }}
                        >
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                            />
                            <TextField
                                id="password"
                                name="password"
                                label={"Senha"}
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((e) => !e)}
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{
                                    margin: "24px 0 16px",
                                    backgroundColor: "rgb(142, 22, 89)"
                                }}
                            >
                                Logar
                            </Button>
                        </form>
                    </div>
                </Paper>
            </div>
        </Container>
    </div>
  );
}
