import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = { current: null };

export default function LocationAutoComplete({ initialLocation, setLocation }) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initAutocomplete`,
            document.querySelector('head'),
            'google-maps',
        );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    const payload = {
      input: inputValue,
      types: ["(regions)"],
      componentRestrictions: { country: 'br' }
    };

    fetch(payload, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, setOptions]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (initialLocation && initialLocation.city && initialLocation.state) {
      const payload = {
        input: `${initialLocation.city} ${initialLocation.state}`,
        types: ["(regions)"],
        componentRestrictions: { country: 'br' }
      };
  
      fetch(payload, (results) => {
        if (active) {
          let newOptions = [];
  
          if (value) {
            newOptions = [value];
          }
  
          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
          handleChange(null, results[0]);
        }
      });
    }

    return () => {
      active = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocation]);

  const handleChange = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);

    if (!geocoder.current && window.google) {
        geocoder.current = new window.google.maps.Geocoder();
    }

    if (newValue) {
        geocoder.current.geocode({ 'placeId': newValue.place_id }, function(results, status) {
          if (status === 'OK') {
            const locationInfo = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              country: '',
              address: '',
              zip: '',
              state: '',
              city: ''
            }

            console.log(results[0]);

            for (let i = 0; i < results[0].address_components.length; i++) {
              const addr = results[0].address_components[i];

              if (addr.types[0] === 'country')
                locationInfo.country = addr.long_name;
              else if (addr.types[0] === 'street_address')
                locationInfo.address = locationInfo.address + addr.long_name;
              else if (addr.types[0] === 'establishment')
                locationInfo.address = locationInfo.address + addr.long_name;
              else if (addr.types[0] === 'route')
                locationInfo.address = locationInfo.address + addr.long_name;
              else if (addr.types[0] === 'postal_code')
                locationInfo.zip = addr.short_name;
              else if (addr.types[0] === 'administrative_area_level_1')
                locationInfo.state = addr.long_name;
              else if (addr.types.includes('locality'))
                locationInfo.city = addr.long_name;
              else if (addr.types.includes('sublocality'))
                locationInfo.city = locationInfo.city + addr.long_name;
            }

            setLocation(locationInfo);
          } else {
            // setLatitude(null);
            // setLongitude(null);
          }
        });
    } else {
      setLocation(null);
        // setLatitude(null);
        // setLongitude(null);
    }
  }

  return (
    <Autocomplete
        id="google-map-demo"
        fullWidth
        getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="Sem endereço"
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        renderInput={(params) => (
            <TextField {...params} label="Endereço" fullWidth />
        )}
        renderOption={(props, option) => {
            const matches =
            option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
            <li {...props}>
                <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                    <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                        {part.text}
                    </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                    </Typography>
                </Grid>
                </Grid>
            </li>
            );
        }}
    />
  );
}
