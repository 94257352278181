import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import UserModal from 'components/UserModal';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';

const Usuario = () => {
  const { setLoading } = useLoading();

  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [rowId, setRowId] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

 useEffect(() => {

   setLoading(true);
    const delay = setTimeout(() => {
      
    
      // * BUSCA TODOS PATROCINIO

      const fetchUser = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/users', { // * LIST
            params: filters
          }); 
          setTableData(data.users);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchUser();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setRowId(null);
    setUserId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setUserId(row.original.user_id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setRowId(null);
    setUserId(null);
    setModalOpen(false);
  };

  const updateTable = (value) => {
    if (rowId) {
      tableData[rowId] = value;
      setTableData([...tableData]);
    } else {
      tableData.push(value);
      setTableData([...tableData]);
    }
  }


  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma exclusão de ${row.getValue('user_id')}`)) return;
        
      try {
        setLoading(true);
        await api.delete('/users/' + row.getValue('user_id')); // * DELETE

        tableData.splice(row.index, 1);
        setTableData([...tableData]);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [tableData, setLoading],
  );
  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'user_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'image',
        header: 'Imagem',
        Cell: ({ cell }) => <img src={cell.getValue()} alt="User" style={{borderRadius: "100%", width: '50px', height: '50px' }} />,
      },
      {
        accessorKey: 'plan.name',
        header: 'Plano'
      },
      {
        accessorKey: 'profile.name',
        header: 'Perfil',
      },
      {
        accessorKey: 'name',
        header: 'Nome',
      },
      {
        accessorKey: 'cell_phone',
        header: 'Telefone',
      },
      {
        accessorKey: 'emergency_contact',
        header: 'Contato de emergência'
      },
      {
        accessorKey: 'integration_token',
        header: 'Token de integração'
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'address',
        header: 'Endereço'
      },
      {
        accessorKey: 'city',
        header: 'Cidade',
      },
      {
        accessorKey: 'state',
        header: 'Estado'
      },
      {
        accessorFn: (row) => row.active ? 'Sim' : 'Não', // * PARA TELA - COLUNA DE BOOLEAN (TRUE / FALSE)
        accessorKey: 'active',
        header: 'Ativo',
        filterVariant: 'checkbox'
      },
      {
        accessorKey: 'number',
        header: 'Número'
      },
      {
        accessorFn: (row) => row.verified ? 'Sim' : 'Não', // * PARA TELA - COLUNA DE BOOLEAN (TRUE / FALSE)
        accessorKey: 'verified',
        header: 'Verificado',
        filterVariant: 'checkbox'
      },
      {
        accessorFn: (row) => row.validation_date ? new Date(row.validation_date) : row.validation_date,
        accessorKey: 'validation_date',
        header: 'Data de validação',
        Cell: ({ cell }) => cell.getValue() ? `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}` : '', 
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`, 
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          style: {
            width: '100%',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"

        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}

        initialState={{
          columnVisibility: { user_id: false },
          columnOrder: [
            'user_id',
            'image',
            'plan.name',
            'profile.name',
            'name',
            'cell_phone',
            'emergency_contact',
            'integration_token',
            'email',
            'address',
            'city',
            'state',
            'number',
            'verified', 
            'active',
            'validation_date',
            'created_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Editar">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Excluir">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" 
          onClick={handleOpenCreateModal} 
          variant="contained"
          >
            Criar Novo
          </Button>
        )}
      />
      <UserModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        userId={userId}
      />
    </>
  );
};

export default Usuario;
